import React, { useState } from "react";

export const PublishPaper = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    console.log("gggggggggggggggggggggggg");
    console.log(formData);
    // Add form submission logic here
  };

  return (
    <div>
      <div className="flex justify-center items-center  bg-gray-100">
        <form className="bg-white p-8 rounded-lg shadow-lg w-full">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">
            Upload Paper
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-4">
              <label
                htmlFor="option"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Select Volume
              </label>
              <select
                id="option"
                name="option"
                value={formData.option}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="option"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Select Issue
              </label>
              <select
                id="option"
                name="option"
                value={formData.option}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Option 1">Option 1</option>
                <option value="Option 2">Option 2</option>
                <option value="Option 3">Option 3</option>
              </select>
            </div>

            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Person Name /Names"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <input
                type="text"
                id="companyname"
                name="companyname"
                value={formData.companyname}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Company Name"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <input
                type="number"
                id="pagefrom"
                name="pagefrom"
                value={formData.pagefrom}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Page from"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <input
                type="number"
                id="pageto"
                name="pageto"
                value={formData.pageto}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Page to"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <input
                type="file"
                id="file"
                name="file"
                value={formData.file}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="file"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <input
                type="date"
                id="date"
                name="pageto"
                value={formData.pageto}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Page to"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <textarea
                id="short"
                name="short"
                value={formData.short}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Short Description / Title"
                rows="5"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              ></label>
              <textarea
                id="Abstract"
                name="Abstract"
                value={formData.Abstract}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Abstract"
                rows="5"
                required
              ></textarea>
            </div>

            <button
              type="button"
              onClick={handleSubmit}
              className="w-sm flex justify-center bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Publish Paper
            </button>
          </div>
        </form>
      </div>

      <div className="flex justify-center items-center h-screen bg-black-10">
        <table className="min-w-full bg-white border border-gray-100">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700 border-b">
                #
              </th>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700 border-b">
                Name
              </th>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700 border-b">
                Email
              </th>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700 border-b">
                Role
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b">
              <td className="py-2 px-4 text-sm text-gray-600">1</td>
              <td className="py-2 px-4 text-sm text-gray-600">John Doe</td>
              <td className="py-2 px-4 text-sm text-gray-600">
                john@example.com
              </td>
              <td className="py-2 px-4 text-sm text-gray-600">Admin</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 px-4 text-sm text-gray-600">2</td>
              <td className="py-2 px-4 text-sm text-gray-600">Jane Smith</td>
              <td className="py-2 px-4 text-sm text-gray-600">
                jane@example.com
              </td>
              <td className="py-2 px-4 text-sm text-gray-600">Editor</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 px-4 text-sm text-gray-600">3</td>
              <td className="py-2 px-4 text-sm text-gray-600">Mark Johnson</td>
              <td className="py-2 px-4 text-sm text-gray-600">
                mark@example.com
              </td>
              <td className="py-2 px-4 text-sm text-gray-600">Viewer</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
