import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col">
      <img src="/banner.jpg" alt="logo" width="100%" />
       
      </div>
      <div className="flex flex-col gap-5 items-center justify-center min-h-screen">
        <div className="flex flex-col p-5 mt-32 bg-white rounded-xl max-w-[80%] min-w-[80%] shadow-2xl border-2 border-black">
          <div className="mb-6">
            <h2 className="text-2xl text-black font-bold mb-2 text-center">
            Intermediary Journals of Engineering Technology and Emerging Management(IJETEM)
            </h2>

            <h2 className="text-2xl text-black font-bold mb-2 text-center">
              
            </h2>
          </div>

          <div className="mb-6">
            <h2 className="text-2xl text-purple-500 font-bold mb-2 text-center">
              About Journal
            </h2>
            <p>
            Home/About Journal
About Journal
Intermediary Journals of Engineering Technology and Emerging Management (IJETEM) is an open access publishing high quality manuscripts covering all aspects of emerging management. Submitted manuscripts are peer reviewed and selection is based on scientific excellence, distinctive contribution to knowledge and importance to the field.

<br></br>
<br></br>
<b>Distinctions:</b> The most widely read, cited, and known emerging management journal and website is well browsed with all the articles published. More than 500 readers per month.
<br></br>
<br></br>
ISSN XXXX-XXXX ;
<br></br>
<br></br>
Intermediary Journals of Engineering Technology and Emerging Management (IJETEM) is an open access publishing high quality manuscripts covering all aspects of emerging management.
<br></br>
<br></br>
Starting Year of Publication: June 2022
<br></br>
Frequency : Quarterly
<br></br>
<br></br>
{/* Format: <a class="ex-btn" href="/file/PaperTemplate.pdf">Download Template</a> */}
Format:<button style={{borderRadius:"20px",width:"200px",backgroundColor:"skyblue", height:"40px"}}>Download Template</button>
<br>
</br>
<br></br>
Format of Publication: Online
<br></br>
Subject: Engineering
<br></br>
Language: English (Only)
<br></br>
<br></br>
<b>Indexed and Abstracted:</b>CrossRef, JISC KB+, SHERPA RoMEO, Cengage Learning, Directory of Open Access Journals (DOAJ), and Google Scholar.
<br></br>
<br></br>
To ensure permanency of all publications, this journal also utilises CLOCKSS, and LOCKSS archiving systems to create permanent archives for the purposes of preservation and restoration.
<br></br>
<br></br>
In addition, all journals are available for harvesting via OAI-PMH.
<br></br>
<br></br>
<b>Rapid publication:</b> Average time from submission to first decision is 30 days and from acceptance to In Press online publication is 45 days.
<br></br>
<br></br>
<b>Open Access Journal:</b> Intermediary Journals of Engineering Technology and Emerging Management is an open access journal, which allows authors to fund their article to be open access from publication.

            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl text-purple-500 font-bold mb-6 text-center ">
              Basic Information
            </h2>

            <div class="overflow-x-auto">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Journal Title
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Intermediary Journals of Engineering Technology and Emerging Management(IJETEM)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Journal Broad Discipline
                    </th>
                    <td class="px-6 py-4">Journal Broad Discipline</td>
                  </tr>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Frequency
                    </th>
                    <td class="px-6 py-4">Frequency Quaterly, four times in a Year</td>
                  </tr>
                  <tr class="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      ISSN
                    </th>
                    <td class="px-6 py-4">2454-8421</td>
                  </tr>
                  <tr class="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Published by
                    </th>
                    <td class="px-6 py-4">
                     Ratu Road, Ranchi, Jharkhand
                    </td>
                  </tr>
                  <tr class="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Indexing
                    </th>
                    <td class="px-6 py-4">National Science Library</td>
                  </tr>
                  <tr class="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Journal Language/s
                    </th>
                    <td class="px-6 py-4">ENGLISH</td>
                  </tr>
                  <tr class="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Current Status (Print/Online/Both)
                    </th>
                    <td class="px-6 py-4">Both</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl text-purple-500 font-bold mb-2 text-center">
              Objective
            </h2>
            <p>
              To investigate the most recent research developments, as well as
              the publication and widespread electronic dissemination of
              innovative and substantial research. Obtaining and soliciting
              high-quality original research papers. To contribute to the
              progress in research by providing a high-quality online platform
              for publishing original research works.
            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl text-purple-500 font-bold mb-2 text-center">
              Aim and Scope
            </h2>
            <p>
            <b>Intermediary Journals of Engineering Technology and Emerging Management (IJETEM)</b> is a progressive, and comprehensive emerging and management science publisher that aim to provide free and easy access to scientific information to all as the scientifically tried and tested knowledge alone can lead to advancement of the societies and communities.

<br></br>
<br></br> 
<b><u>Vision:</u></b>
 As the information and knowledge empowers communities from taking the right decision and save the communities from the emerging management, we aim to bring the latest from the emerging and management advancements to the society. We are equally keen in encouraging the scientists working relentlessly in these fields to share their knowledge and research outcome with the society. We believe in the crowdsourcing movement and consider ourselves as a collaborative publisher. With a view to remain accessible to all, we have set the most affordable article processing charges of all Engineering Technology and management.
<br></br>
<br></br>
<b><u>Mission:</u></b>
 <b>Intermediary Journals of Engineering Technology and Emerging Management (IJETEM)</b> aims to uphold the rights of authors, address their needs, and foster a rapid, convenient, unbiased, and comprehensive publishing environment, which not only guarantees the highest quality constructive peer-review process, but also provides an evaluation system that involves the entire research community.

Explore our websites to know more about our commitment towards open access knowledge sharing and the innovative publishing methods, including author guidelines, type of articles we publish and the standard review procedures we adopt. We facilitate quick review process while adhering to quality publication standards. Upcoming researchers and scholars can explore our site to ripe the benefits of Open access knowledge sharing to promote further research and knowledge upgradation.
            </p>
          </div>
          <div className="mb-6">
            <h2 className="text-2xl text-purple-500 font-bold mb-2 text-center">
              GuideLines
            </h2>
            <p>
              Authors are invited to submit original and unpublished 6-page
              papers in IEEE double column format in MS Word document formats
              (.doc or.docx) to be considered for publication as a regular paper
              in future issues of this journal. Authors can submit their
              manuscripts through email or submit online at the website of this
              journal mentioned below.
            </p>
            <span
              className="underline text-purple-500 cursor-pointer font-semibold hover:text-green-500"
              onClick={() => navigate("/author-guidelines")}
            >
              GuideLines
            </span>
          </div>
          
            <div className="mb-6 border border-black rounded-md p-2 hover:scale-105 transition-all">
              <h2 className="text-xl text-purple-500 text-center font-bold mb-2 ">
                Issues
              </h2>
              <p>
                IJETEM welcomes original and contemporary research in the broad
                area of Technology and Management, both conceptual and
                empirical. Authors are invited to submit their research
                IJETEMbutions for the forthcoming edition. The Journal Publishes
                in both print and online version. The journal publishes research
                articles in Technology, and Management on theoretical
                foundations, computational, practical techniques, application,
                and implementations.
              </p>
            </div>
            <div className="mb-6 border border-black rounded-md p-2 hover:scale-105 transition-all">
              <h2 className="text-xl text-purple-500 text-center font-bold mb-2">
                Paper
              </h2>
              <p>
                IJETEM is pleased to announce the launching of its first bi-annual
                multi-disciplinary journal – Bodh: IJETEM International Journal of
                Technology and Management in May-June, 2015. The journal will
                help to build emerging fields of study and enhance communication
                within existing research fraternity in technology and
                management. The journal aims at publishing quality research
                papers that are theoretical, empirical and application oriented
                and promotes the link between Technology and Management.
              </p>
            </div>
            <div className="mb-6 border border-black rounded-md p-2 hover:scale-105 transition-all">
              <h2 className="text-xl text-purple-500 text-center font-bold mb-2">
                Policy
              </h2>
              <p>
                Plagiarism is when an author attempts to pass off someone else
                work as his or her own. Duplicate publication, sometimes called
                self-plagiarism, occurs when an author reuses substantial parts
                of his or her own published work without providing the
                appropriate references. This can range from getting an identical
                paper published in multiple journals, to salami-slicing, where
                authors add small amounts of new data to a previous paper.
              </p>
            </div>
          
        </div>
      </div>
    </>
  );
};

export default Home;
