import React from "react";

const AimScope = () => {
  
  return (
    <div className="flex flex-col gap-5 items-center justify-center min-h-screen">
      <div className="flex flex-col p-5 my-32 bg-white rounded-xl max-w-[80%] min-h-[40%] shadow-2xl border-2 border-black">
        <h1 className="text-black text-3xl font-bold mb-3 text-center">
          Aim and Scope
        </h1>
        <h2 className="text-purple-500 text-2xl font-bold mb-3 text-center">
          IJETEM AIM
        </h2>
        <p className="mb-2">
          <b>
            Intermediary Journals of Engineering Technology and Emerging
            Management (IJETEM)
          </b>{" "}
          is a progressive, and comprehensive emerging and management science
          publisher that aim to provide free and easy access to scientific
          information to all as the scientifically tried and tested knowledge
          alone can lead to advancement of the societies and communities.
        </p>
        <br></br>
        <br></br>
        <b>
          <u>Vision:</u>
        </b>
        <br></br>
        As the information and knowledge empowers communities from taking the
        right decision and save the communities from the emerging management, we
        aim to bring the latest from the emerging and management advancements to
        the society. We are equally keen in encouraging the scientists working
        relentlessly in these fields to share their knowledge and research
        outcome with the society. We believe in the crowdsourcing movement and
        consider ourselves as a collaborative publisher. With a view to remain
        accessible to all, we have set the most affordable article processing
        charges of all Engineering Technology and management.
        <br></br>
        <br></br>
        <b>
          <u>Mission:</u>
        </b>
        <br></br>
        <b>
          Intermediary Journals of Engineering Technology and Emerging
          Management (IJETEM)
        </b>{" "}
        aims to uphold the rights of authors, address their needs, and foster a
        rapid, convenient, unbiased, and comprehensive publishing environment,
        which not only guarantees the highest quality constructive peer-review
        process, but also provides an evaluation system that involves the entire
        research community. Explore our websites to know more about our
        commitment towards open access knowledge sharing and the innovative
        publishing methods, including author guidelines, type of articles we
        publish and the standard review procedures we adopt. We facilitate quick
        review process while adhering to quality publication standards. Upcoming
        researchers and scholars can explore our site to ripe the benefits of
        Open access knowledge sharing to promote further research and knowledge
        upgradation.
        <br></br>
        <br></br>
        <h4>Objectives</h4>
        <ol></ol>
        <p>
          <ul>To provide a new publishing system that </ul>
        </p>
        <ol></ol>
        <ul class="list-disc">
          <li class="ml-3">
            {" "}
            Drives an increase in the quality of science world-wide
          </li>
        </ul>
        <ul class="list-disc">
          <li class="ml-3"> Disseminates science freely</li>
        </ul>
        <ul class="list-disc">
          <li class="ml-3"> Distills academic excellence</li>
        </ul>
        <ul class="list-disc">
          <li class="ml-3">
            {" "}
            Delivers the most outstanding and relevant science to the public
          </li>
        </ul>
        <ul class="list-disc">
          <li class="ml-3"> Provides a new academic model for review</li>
        </ul>
        <p>
          {" "}
          To provide an evaluation and recognition system for authors that is
        </p>
        <ul class="list-disc">
          <li class="ml-3"> Rapid and efficient</li>
          <li class="ml-3"> Fair and constructive</li>
          <li class="ml-3"> Rigorous and prestigious</li>
        </ul>
        <p>To provide a new business model that</p>
        <ul class="list-disc">
          <li class="ml-3"> Serves publishing needs of researchers</li>
        </ul>
        <ul class="list-disc">
          <li class="ml-3">
            {" "}
            Obtains sponsors to support open access research publishing
          </li>
        </ul>
        <ul class="list-disc">
          <li class="ml-3">
            {" "}
            Aid Engineering Technology in reinforcing their brand messages, and
            to produce customized content to integrate with global and local
            scientific community
          </li>
        </ul>
        <p>The objectives of the journal are as:</p>
        <ul class="list-disc">
          <li class="ml-3">
            {" "}
            To investigate the most recent research developments, as well as the
            publication and widespread electronic dissemination of innovative
            and substantial research.
          </li>
          <li class="ml-3">
            Obtaining and soliciting high-quality original research papers.
          </li>
          <li class="ml-3">
            To strengthen readers' research knowledge and to serve as an
            effective platform for the promotion of scientific and managerial
            exchange of knowledge and recent developments among active
            researchers, industry personage, engineers, scientists, and
            students.
          </li>
          <li class="ml-3">
            To contribute to the progress in research by providing a
            high-quality online platform for publishing original research works.
          </li>
        </ul>
        <h2 className="text-purple-500 text-2xl font-bold mb-3 mt-5 text-center mt-5">
          IJETEM SCOPE
        </h2>
        <p className="mb-2">
          Welcomes original and contemporary research in the broad area of
          Technology and Management, both conceptual and empirical. Authors are
          invited to submit their research contributions for the forthcoming
          edition. The Journal Publishes in both print and online version. The
          journal publishes research articles in Technology, and Management on
          theoretical foundations, computational, practical techniques,
          application, and implementations.
        </p>
        <p className="mb-3">
          The journal is currently accepting research paper for publication in
          the following fields of :
        </p>
        <ul className="list-disc">
          <li className="ml-3">
            {" "}
            <b>
              Computer Science and Engineering and Information Technology:
            </b>{" "}
            Artificial Intelligence, Machine Learning, Deep Learning, Big Data
            Analytics, Cloud Computing, Computer Networks, Data Mining,
            Information &amp; Network Security, Natural Language Processing,
            Soft Computing, Software Engineering.
          </li>
          <li className="ml-3">
            {" "}
            <b>Applied Sciences:</b> Atomic, Molecular &amp; Optical Physics,
            Condensed Matter Physics Corrosion Engineering, Differential
            Equation, Electro Chemistry, Polymers, Materials Sciences,
            Theoretical Particle Physics, Mathematical Modelling.
          </li>
          <li className="ml-3">
            {" "}
            <b>Electrical:</b> Electrical Machines &amp; Drives, Energy Systems,
            Energy and Power Technologies, Computer Vision, Image Processing,
            Power Systems, Renewable Energy Sources.
          </li>
          <li className="ml-3">
            {" "}
            <b>Electronics and Communication:</b> Signal Processing, Wireless
            Communication, VLSI &amp; Microelectronics, Mobile Computing, RF
            &amp; Microwave Engineering, Robotics &amp; Automation, Internet of
            Things, Remote Sensing, Smart Cities Nano Materials, Computer
            Vision, Biomedical Imaging, Control Systems, Controls &amp; Dynamics
            of Complex Systems.
          </li>
          <li className="ml-3">
            {" "}
            <b>Management:</b> Organizational Innovation, Green Technologies,
            Business Continuity Cross Cultural Management, Corporate
            Sustainability, Total Quality Management, Technology Management,
            Change Management, Entrepreneurship, Business &amp; Ethics
            E-commerce, Role of SMEs in National Economies, Emerging Role of
            Women in Business, Risk Management, Knowledge Management, Strategy,
            Systems and Governance, Knowledge Life Cycles in Organizations,
            Knowledge Acquisition, Transfer Processes &amp; Reuse in
            Organizations, Transformational Leadership, Business Process,
            Reengineering, Corporate Governance, Accounting &amp; Taxation,
            Process Innovation Enterprise Resource Planning, Benchmarking, Rural
            Management, Crisis Management, Business Intelligence, Logistics and
            Supply Chain Management, Strategic Management, Corporate Social
            Responsibility, Environmental Management, Intellectual Property
            Rights Tourism and Hospitality Management, Healthcare Management,
            International Management Practices, Marketing, Human Resource
            Management, Operations Management, Finance, Global Business,
            Economics, Management Science and Statistics, Digital Business,
            Business Law, Finance, Marketing, Human Resource management,
            E-commerce, Operations, Supply Chain management, Economics,
            International business.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AimScope;
